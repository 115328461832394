<template>
  <div>
    <div class="bg_top_linear-gradient" style="background: #ffffff;height: auto;width: auto;">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" color="#333333" @click="backPath" />
        <div class="top_name" style="color: #333333;">手机注册</div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 验证手机号是否相同 -->
    <div v-if="regCheck" class="page" style="padding: 0.27rem;margin: 0rem;background:#f7f7f7">
      <!-- 营业执照 -->
      <div class="certificationReg_img_box" style="height: auto;padding-bottom: 0.3rem;">
        <div class="box_top flex-r-sb-c">
          <div class="img_name" style="margin-left: 0.3rem;font-weight: bold;">营业执照</div>
        </div>
        <van-image width="6.29rem" height="3.68rem" :src="certificationForm.ocrUrl" />
        <van-form style="padding-top: 0.3rem;">
          <van-field v-model="certificationForm.companyName" label="公司名称" placeholder="公司名称" readonly />
          <van-field v-model="certificationForm.businessLicense" label="三证合一码" readonly placeholder="三证合一码" />
          <van-field v-model="certificationForm.legalPerson" label="法人姓名" readonly placeholder="法人姓名" />
        </van-form>
      </div>
      <!-- 注册信息 -->
      <div class="certificationReg_img_box" style="height: auto;margin-top: 0.3rem;padding-bottom: 0.3rem;">
        <div class="box_top flex-r-sb-c">
          <div class="img_name" style="margin-left: 0.3rem;font-weight: bold;">手机注册</div>
        </div>
        <van-form>
          <van-field v-model="certificationForm.mobile" label="手机号码" placeholder="请填写法人实名认证的手机号码" />
          <van-field readonly clickable :value="certificationForm.districtName" label="所在地区" placeholder="请选择所在地区"
                     right-icon="arrow" @click="showPicker = true"
          />
          <div class="login_sub" style="margin-top: 0.9rem;margin-left: 0.2rem; ">
            <van-button round block type="info" color="#0767ab" style="font-size: 0.43rem;"
                        class="next_btn position_fixed" @click="onSubmit"
            >去注册 </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <!-- 注册登录账户 -->
    <div v-else class="page" style="margin: 0.27rem;">
      <van-image width="2.67rem" height="2.67rem" style="margin-top: 0.91rem;"
                 src="https://oss.xiaoyi120.com/shop2.0/login/login_logo_reg.png"
      />
      <div class="reg_check_name">请输入验证码</div>
      <div class="reg_check_mobile">{{ regInfo.mobile }}</div>
      <div class="reg_check_companyName">{{ regInfo.companyName }}</div>
      <van-password-input :value="value" :error-info="errorInfo" :focused="showKeyboard" :mask="false" :gutter="10"
                          @focus="showKeyboard = true"
      />
      <van-number-keyboard v-model="value" :show="showKeyboard" @blur="showKeyboard = false" />
      <div class="reg_check_reSend">
        <van-count-down v-if="isSend" :time="time" format="重新发送（ss）" style="color: #999999;" @finish="finish" />
        <div v-else class="send_message" @click="sendMessage">重新发送</div>
      </div>
    </div>
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
    <!-- 手机非法人实名号码 -->
    <van-dialog v-model="mobileShow01" :show-confirm-button="false">
      <div class="mobileShow_dialog flex-c-c-c">
        <van-image width="3.89rem" height="3.04rem"
                   src="https://oss.xiaoyi120.com/shop2.0/home/certification.png"
        />
        <div class="certification_title"> 手机非法人实名号码 </div>
        <div class="certification_tip"> 请填写法人实名认证的手机号注册 </div>
        <van-button class="certification_btn" round type="info" color="#0767ab" @click="mobileShow01 = fasle">知道了
        </van-button>
      </div>
    </van-dialog>
    <!-- 手机号已注册 -->
    <van-dialog v-model="mobileShow02" :show-confirm-button="false">
      <div class="mobileShow_dialog flex-c-c-c">
        <van-image width="3.89rem" height="3.04rem"
                   src="https://oss.xiaoyi120.com/shop2.0/home/certification.png"
        />
        <div class="certification_title"> 手机号已注册 </div>
        <div class="certification_tip"> 该手机号已注册，可使用该手机号直接登录 </div>
        <van-button class="certification_btn" round type="info" color="#0767ab" @click="mobileShow02 = fasle">知道了
        </van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import './index.scss'
import { districts } from '@/api/public'
import { mobileIsReal, sms_send, authAndReg } from '@/api/user'
export default {
  name: 'CertificationReg02',
  data() {
    return {
      regSignId: '',
      openid: '',

      step: 2,
      certificationShow: true,
      fileList01: [],
      loading: false,
      certificationForm: {},

      showPicker: false,
      cascadervalue: '',
      columns: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      regCheck: true,
      regInfo: { mobile: '', companyName: '', platformType: 'wxmp', categoryId: 1 },

      value: '',
      errorInfo: '',
      showKeyboard: true,
      time: 60 * 1000,
      isSend: true,

      mobileShow01: false,
      mobileShow02: false

    }
  },
  watch: {
    value(value) {
      if (value.length === 6) {
        this.regMobile()
      } else {
        this.errorInfo = ''
      }
    }
  },
  created() {
    this.certificationForm = {
      businessLicense: this.$route.query.businessLicense,
      companyName: this.$route.query.companyName,
      legalPerson: this.$route.query.legalPerson,
      ocrSignId: this.$route.query.ocrSignId,
      ocrUrl: this.$route.query.ocrUrl,
      districtName: '', mobile: ''
    }
    this.openid = this.$route.query.openId
    this.loadDistrict()
  },
  methods: {
    backPath() { window.history.back() },
    // 加载省市区
    loadDistrict() { districts().then(res => { this.columns = res.data }) },
    // 选择结束
    finishPick(value) {
      console.log(value)
      this.showPicker = false
      this.certificationForm.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
      this.certificationForm.provinceId = value.selectedOptions[0].value
      this.certificationForm.cityId = value.selectedOptions[1].value
      this.certificationForm.areaId = value.selectedOptions[2].value

      this.regInfo.provinceId = value.selectedOptions[0].value
      this.regInfo.cityId = value.selectedOptions[1].value
      this.regInfo.areaId = value.selectedOptions[2].value
    },
    // 关闭选择
    closePick() { this.showPicker = false },
    // 点击登录
    onSubmit(values) {
      this.loading = true
      mobileIsReal(this.certificationForm).then(res => {
        if (res.code === 40024) {
          this.mobileShow01 = true; this.loading = false
        } else if (res.code === 40025) {
          this.mobileShow02 = true; this.loading = false
        } else {
          this.loading = false
          const phone = JSON.stringify(JSON.parse(this.certificationForm.mobile))
          this.regInfo.mobile = phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
          this.regInfo.companyName = this.$route.query.companyName
          this.regCheck = false
          this.regSignId = res.data.regSignId
          this.sendMessage()
        }
      }).catch(() => {

      })
    },
    // 发送短信
    sendMessage() {
      const parms = { mobile: this.certificationForm.mobile, type: 'login' }
      sms_send(parms).then(res => {
        this.isSend = true
      })
    },
    // 倒计时结束触发D
    finish() { this.isSend = false },
    // 注册登录
    regMobile() {
      const parms = { checkCode: this.value, regSignId: this.regSignId, openId: this.openid }
      authAndReg(parms).then(res => {
        this.$toast({ message: '注册成功！', duration: 3 * 1000 })
        res.data.openid = this.openid
        res.data.ref = this.url
        this.$utils.storeUserInfo(this.$store, res.data)
        this.$utils.localLogin(this, res.data, 'login_pet')
      }).catch(err => {
        const phone = JSON.stringify(JSON.parse(this.certificationForm.mobile))
        this.regInfo.mobile = phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
        console.log(err)
        this.value = ''
      })
    }
  }
}
</script>
